/* custom.css */
.custom-navbar {
    background-color: #f8f9fa; /* Light grey background */
}

.custom-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
}

.custom-button:hover {
    background-color: #555;
}
